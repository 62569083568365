import { Component, OnInit, Input } from '@angular/core';
import {textoLenguajes} from './../common/common';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss']
})
export class ContactoComponent implements OnInit {

  @Input()
  idioma:string;
  textos: Object;
  nombre:string;
  correo:string;
  asunto:string;
  mensaje:string;
  constructor() { 
    this.textos = textoLenguajes;
    this.nombre="";
    this.correo="";
    this.asunto="";
    this.mensaje="";
  }

  ngOnInit() {
    let cmodal:HTMLElement = document.querySelector('#mioModal');
    cmodal.style.display='none';
    window.onclick = function(event){
      if(event.target == cmodal){
        cmodal.style.display='none';
      }  
    }
  }

  getTexto(tipo:string){
		return this.textos[tipo][this.idioma];
  }
  sendEmail(){
    let xhttp = new XMLHttpRequest();
    let vm = this;
    let cmodal:HTMLElement = document.querySelector('#mioModal');
    cmodal.style.display='none';
    xhttp.onreadystatechange = function(){
      if(this.readyState == 4 && this.status == 200){
        if(vm.idioma=='es'){
          document.querySelector('#cmensajeModal').innerHTML='Se envío el mensaje exitosamente';
        }
        else if(vm.idioma=='en'){
          document.querySelector('#cmensajeModal').innerHTML='The message was sent successfully';
        }
        cmodal.style.display='block';        
      }
    };
    xhttp.open("POST","RestController.php",true);
    xhttp.setRequestHeader("Content-type","application/x-www-form-urlencoded");
    xhttp.send("xnombre="+this.nombre+"&xcorreo="+this.correo+"&xasunto="+this.asunto+"&xmensaje="+this.mensaje);
  }

}
