const textoLenguajes = {
    menu1:{
      es:"Home",
      en:"Home"
    },
     menu2:{
      es:"Conócenos",
      en:"About us"
    },
     menu3:{
      es:"Producto",
      en:"Products"
    },
     menu4:{
      es:"Exportación",
      en:"Exportation"
    },
     menu5:{
      es:"Certificados",
      en:"Certificates"
    },
     menu6:{
      es:"Galería",
      en:"Gallery"
    },
    menu7:{
      es:"Contacto",
      en:"Contacts"
    },
    menu8:{
      es:"Políticas y Línea Ética",
      en:"Policies and Ethical Line"
    },
    menu9:{
      es:"Sostenibilidad y RSE",
      en:"Sustainability y RSE"
    },
    menu10:{
      es:"Documents",
      en:"Documentos"
    },
    menu11:{
      es:"Conocenos",
      en:"know us"
    },
    menu12:{
      es:"Producto",
      en:"Products"
    },
    menu13:{
      es:"Certificado",
      en:"Certificado"
    },
    menu14:{
      es:"Galeria",
      en:"Galeria"
    },
    submenu1:{
      es:"¿Quienes somos?",
      en:"About us?"
    },
    submenu2:{
      es:"Principios",
      en:"Principios"
    },
    submenu3:{
      es:"Nuestras Politicas",
      en:"Our Policies"
    },
    submenu10:{
      es:"Politicas y declaraciones",
      en:"Policies and statements"
    },
    poli:{es:"Corporación Agroexpórtica es consciente que por la naturaleza de sus actividades podría estar expuesta a riesgos de corrupción, lavado de activos, entre otros. ",
          en:"Corporación Agroexpórtica is aware that due to the nature of its activities it could be exposed to risks of corruption, money laundering, among others."
  },
   poli2:{es:"El respeto por los derechos humanos, el comercio ético, el cuidado del medio ambiente y la seguridad y salud de nuestros colaboradores también son parte fundamental para las labores realizadas dentro de la empresa.",
          en:"Respect for human rights, ethical trade, care for the environment and the safety and health of our collaborators are also a fundamental part of the work carried out within the company."

   },
    submenu4:{
      es:"Responsabilidad Social",
      en:"Social responsability"
    },
    submenu5:{
      es:"Sostenibilidad",
      en:"Sustainability"
    },
    revisalineamientos:{
      es:"Revisa nuestros lineamientos aqui",
      en:"Review our guidelines here"
    },
    respon:{
      es:"Somos una empresa que contribuye de manera permanente con organizaciones cuyos fines se basan en la ayuda social a personas de diversas edades en situaciones vulnerables",
      en:"We are a company that contributes permanently to organizations whose purposes are based on social assistance to people of various ages in vulnerable situations."
    },
    sostenib:{
      es:"Conocemos a las abejas como productoras del  delicioso alimento que es la miel, sin considerar  que el mayor beneficio de su existencia es su colaboración en la polinización de gran cantidad  de cultivos. La abeja, por su estructura, agilidad y  población, es el principal y más importante polinizador de diversos alimentos que se desarrollan en el campo.",
      en:"We know bees as producers of the delicious food that is honey, without considering that the greatest benefit of their existence is their collaboration in the pollination of a large number of crops. The bee, due to its structure, agility and population, is the main and most important pollinator of various foods that grow in the countryside."
    },
    presen:{
      es:"Trabajamos de acuerdo a las necesidades requeridas por nuestros clientes. Contamos con variedad de calibres y distintas presentaciones. ",
      en:"We work according to the needs required by our clients. We have a variety of calibers and different presentations. "
    }
    ,
    submenu6:{
      es:"Disponibilidad",
      en:"Availability"
    },
    submenu7:{
      es:"Presentaciones",
      en:"Presentations"
    },
    tituloHome1:{
      es:"CORPORACIÓN",
      en:"AGROEXPORTICA "
    },
    tituloHome2:{
      es:"AGROEXPÓRTICA",
      en:"CORPORATION"
    },
    subtituloHome1:{
      es:"Brindar la mejor calidad,",
      en:"Provide the best quality,"
     },
     subtituloHome2:{
      es:"es en Corporación Agroexpórtica nuestra prioridad",
      en:"It’s in  Agroexportica Corporation our priority"
     },
     tituloAbout:{
      es:"CONÓCENOS",
      en:"ABOUT US"
      },
     subtituloAbout:{
      es:"Corporación Agroexpórtica SAC se fundó en el año 2008. Desde que iniciamos las labores en el mercado peruano, nuestra empresa tiene como principal objetivo el cultivo, producción y exportación de espárragos con los mejores estándares de calidad. Teniendo en cuenta la conservación del medio ambiente, la salud y satisfacción de los consumidores y trabajadores de la empresa.",
      en:"Agroexportica Corporation Sac was founded in 2008. Since we started labors in the Peruvian market our company has as its main objective the cultivation, production and exportation of asparagus with the best quality standards. Considering the conservation of the environment, health and satisfaction of our consumers and company workers. "
     },
     subtituloQuienesosmos:{
      es:"Nuestra empresa se fundo en el año 2008 y desde que iniciamos labores en el mercado peruano, tenemos como principal objetivo el cultivo, producción y exportación de espárragos con los mejores estandares de calidad",
      en:"Our company was founded in 2008 and since we began working in the Peruvian market, our main objective is the cultivation, production and export of asparagus with the best quality standards."
     },
     subtituloQuienesosmos2:{
      es:"Teniendo en cuenta la conservación del medio ambiente, la salud y satisfacción de los consumidores y colaboradores de la empresa",
      en:"Taking into account the conservation of the environment, the health and satisfaction of consumers and company collaborators"
     },
      misionTitulo:{
      es:"Misión",
      en:"Mission"
     },
     misionTexto:{
      es:"Buscar la máxima elaboración de productos de excelente calidad para abastecer mercados competitivos del exterior.",
      en:"To search the maximum elaboration of quality products to supply competitive markets abroad. "
     },
      visionTitulo:{
      es:"Visión",
      en:"Vision"
     },
     visionTexto:{
      es:"Ser considerados como una empresa líder en sistemas de calidad agrícola para la producción.",
      en:"Be considered as a leading company in agricultural quality systems for production."
     },
     teamTitulo:{
      es:"Trabajo en equipo",
      en:"Teamwork"
     },
     teamvision_1:{
      es:"Nuestra misión: ofrecer un producto que cumpla con los mejores estándares de calidad, satisfaciendo a los mercados altamente exigentes, de la mano de accionistas dedicados y comprometidos con el bienestar de todos sus colaboradores.",
      en:"Our mission: to offer a product that meets the best quality standards, satisfying highly demanding markets, with the help of dedicated shareholders committed to the well-being of all its collaborators."
     },
     teamvision_2:{
      es:"Nuestra visión: ser una empresa líder en producción y exportación de esparragos frescos de calidad",
      en:"Our vision: to be a leading company in the production and export of quality fresh asparagus"
     },
     teamvision_3:{
      es:"Constancia y dedicacion",
      en:"Consistency and dedication"
     },
     teamvision_4:{
      es:"Trabajo en equipo",
      en:"Teamwork"
     },
     teamvision_5:{
      es:"responzabilidad",
      en:"responsibility"
     },
     teamvision_6:{
      es:"Compromiso",
      en:"Commitment"
     },
     teamvision_7:{
      es:"Fortaleza",
      en:"Strength"
     },
     teamvision_8:{
      es:"Respeto",
      en:"Respect"
     }
     ,
     teamTexto:{
      es:"Trabajar de manera ordenada y optimizada, contribuye a que el grupo empresarial tenga un nivel elevado de rendimiento y productividad.",
      en:"Working in an orderly and optimized way, contributes to the business group has a high level of performance and productivity."
     },
      compromisoTitulo:{
      es:"Compromiso",
      en:"Commitment"
     },
     compromisoTexto:{
      es:"Tenemos la firmeza inquebrantable para cumplir nuestras metas, actuando consecuentemente para el bienestar de nuestros trabajadores y el medio ambiente.",
      en:"We have the unwavering firmness to meet our goals. Acting accordingly for the well-being of our workers and the environment."
     },
     tituloProducto:{
      es:"PRODUCTO",
      en:"PRODUCT"
      },
     tituloSlider1:{
      es:"Nuestro espárrago",
      en:"Our Asparagus"
     },
     textoSlider1:{
      es:"Es un vegetal nativo del Mediterráneo. Su nombre científico es asparagus officinalis. Su cultivo dura más de 10 años y se corta más o menos a 23 cm.",
      en:"It’s a native vegetable of the Mediterranean. Its cultivation lasts more than 10 years and is cut more or less to 23 cm."
     },
     tituloSlider2:{
      es:"Coronas de espárragos",
      en:"Asparagus Crowns"
     },
     textoSlider2:{
      es:"Las coronas de espárragos se producen a partir de la semilla variedad Atlas F1 qué son sembradas en camas previamente preparadas que posteriormente son transplantadas en el campo.",
      en:"Asparagus crowns are produced from the Atlas F1 seed, which are sown in previously prepared beds that are then transplanted in the field. "
     },
     textoSlider21:{
      es:"En el fundo La Parra cosechamos dos veces al año entre los meses de marzo y abril y agosto y noviembre.",
      en:"At La Parra farm we harvest twice a year between the months of March and April and August and November."
     },
      tituloSlider3:{
      es:"Propiedades",
      en:"Properties"
     },
     textoSlider3:{
      es:"Es un alimento rico en fibra, calcio, fósforo, hierro, vitamina A, B1, B2, C y ácido fólico.",
      en:"Asparagus is rich in fiber, calcium, phosphorus, iron, vitamin A, B1, B2, C and folic acid."
     },
     textoSlider31:{
      es:"Contribuye a una adecuada circulación sanguínea.",
      en:"Contributes to adequate blood circulation."
     },
     textoSlider32:{
      es:"Tiene agentes diuréticos que reducen hipertensión retención de líquidos y enfermedades a vejiga y diabetes.",
      en:"It has diuretic agents that reduce hypertension fluid retention and bladder diseases and diabetes."
     },
     tituloPackaging:{
      es:"PACKAGING",
      en:"PACKAGING"
      },
     subtituloPackaging:{
      es:"Nuestro embalaje pasa por controles rigurosos para que el producto se conserve y llegue en perfectas condiciones a su destino.",
      en:"Our packaging goes through rigorous controls so the product is preserved and arrives in perfect condition at its destination."
     },
     tituloCertificado:{
      es:"CERTIFICACIONES",
      en:"CERTIFICATIONS"
      },
     certificadoHaccp:{
      es:"HACCP Codex Alimentarius ",
      en:"HACCP Codex Alimentarius "
     },
     certificadoGlobal:{
      es:"Certificado GLOBALGAP",
      en:"GLOBALGAP Certificate "
     },
      tituloGaleria:{
      es:"GALERÍA",
      en:"GALLERY"
      },
      tituloContacto:{
      es:"CONTÁCTENOS",
      en:"CONTACT US "
      },
      datoContacto1:{
      es:"COMUNÍCATE CON NOSOTROS",
      en:"SPEAK WITH US"
      },
      datoContacto2:{
      es:"CONTÁCTANOS",
      en:"CONTACT US "
      },
       datoContacto3:{
      es:"Enviar",
      en:"Send"
      },
      name:{
        es:"Nombre",
        en:"Name"
      },
      asunto:{
          es:"Asunto",
          en:"Subject"
      },
      email:{
            es:"Email",
            en:"Correo"
      },
      msg:{
            es:"Mensaje..",
            en:"Message.."
      },
      oficina:{
        es:"Oficina",
        en:"Office"
      },
      urlTipoHc:{
        es:"certificado.pdf",
        en:"assets/images/exporta/CERTIFICADOHACCPINGLES.pdf"
      },
      urlTipoGl:{
        es:"assets/images/exporta/certGLOBALGAPIngles.pdf",
        en:"assets/images/exporta/certGLOBALGAP.pdf"
      },
      subTituloPoliticas:{
        es:"En CORPORACIÓN AGROEXPORTICA S.A.C garantizamos productos de calidad, dirigiendo nuestras actividades a la plena satisfacción de nuestros clientes y trabajadores, así como el compromiso en Calidad, Responsabilidad social, Seguridad, Salud en el Trabajo y Medio Ambiente en cada una de nuestras sedes. Consideramos que nuestro capital más importante son nuestros trabajadores por eso garantizamos condiciones y ambientes de trabajo seguros, saludables y dignos.",
        en:"At CORPORACIÓN AGROEXPORTICA S.A.C we guarantee quality products, directing our activities to the full satisfaction of our clients and workers, as well as the commitment to Quality, Social Responsibility, Safety, Health at Work and the Environment in each of our locations. We consider that our most important capital is our workers, therefore we guarantee safe, healthy and dignified working conditions and environments."
      },
      politicaTitulo:{
        es:"Políticas",
        en:"Policies"
      },
      politicaComercio:{
        es:"Política de comercio ético",
        en:"Ethical Trading Policy"
      },
      urlPoliticaComercio:{
        es:"assets/images/politicas/politicaComercioEticoES.pdf",
        en:"assets/images/politicas/politicaComercioEticoEN.pdf"
      },
      politicaRespeto:{
        es:"Política de respeto de los derechos humanos",
        en:"Policy of Respect for Human Rights"
      },
      urlPoliticaRespeto:{
        es:"assets/images/politicas/politicaRespetoDerechosHumanosES.pdf",
        en:"assets/images/politicas/politicaRespetoDerechosHumanosEN.pdf"
      },
      politicaSeguridad:{
        es:"Política del sistema de gestión de seguridad y salud en el trabajo",
        en:"Policy of the Health and Safety Management System in the Job"
      },
      urlPoliticaSeguridad:{
        es:"assets/images/politicas/politicaSeguridadES.pdf",
        en:"assets/images/politicas/politicaSeguridadEN.pdf"
      },
      politicaAmbiental:{
        es:"Política medio ambiental",
        en:"Environmental Policy"
      },
      urlPoliticaAmbiental:{
        es:"assets/images/politicas/politicaMedioAmbienteES.pdf",
        en:"assets/images/politicas/politicaMedioAmbienteEN.pdf"
      },
      eticaTitulo:{
        es:"Línea Ética",
        en:"Ethical Line"
      },
      eticaConducta:{
        es:"Código de ética y conducta",
        en:"Code of Ethics and Conduct"
      },
      urlEticaConducta:{
        es:"assets/images/politicas/politicaEticaConductaES.pdf",
        en:"assets/images/politicas/politicaEticaConductaEN.pdf"
      },
      eticaPolitica:{
        es:"Política contra el soborno y la corrupción",
        en:"Bribes and Corruption Policy"
      },
      urlEticaPolictica:{
        es:"assets/images/politicas/politicaSobornoCorrupcionES.pdf",
        en:"assets/images/politicas/politicaSobornoCorrupcionEN.pdf"
      },
      eticaContacto:{
        es:"Contáctanos",
        en:"Contact Us"
      },
      eticaTelefono:{
        es:"948-885-641",
        en:"948-885-641"
      },
      eticaMail:{
        es:"etica@corporacionica.com",
        en:"etica@corporacionica.com"
      },
      tituloSostenibilidad:{
        es:"Sostenibilidad ",
        en:"Sustainability "
      },
      subTituloSostenibilidad:{
        es:"Corporación Agroexportica SAC contribuye de manera permanente con La FAZ, una organización cuyo fin es la ayuda social a personas de diversas edades en situaciones vulnerables. Además de ser un COLABORADOR en distintas situaciones de necesidad.",
        en:"Corporación Agroexportica SAC contributes permanently to La FAZ, an organization whose purpose is to provide social assistance to people of various ages in vulnerable situations. In addition to being a COLLABORATOR in different situations of need."
      },
      politicaComercioEtico:{
        es:"Politica Comercio Etico",
        en:"Ethical Trade Policy"
      },
      politicaComercioEticopdf:{
        es:"politicaComercioEticoES.pdf",
        en:"politicaComercioEticoEN.pdf"
      }
      ,
      politicaEticaConducta:
      {
        es:"Politica Etica Conducta",
        en:"Policy Ethics Conduct"
      },
      politicaEticaConductapdf:
      {
        es:"politicaEticaConductaES.pdf",
        en:"politicaEticaConductaEN.pdf"
      }
      ,
      politicaMedioAmbiente:{
        es:"Politica Medio Ambiente",
        en:"Environmental Policy"
      },
      politicaMedioAmbientepdf:{
        es:"politicaMedioAmbienteES.pdf",
        en:"politicaMedioAmbienteEN.pdf"
      }
      ,
      politicaRespectoDerechosHumanos:{
        es:"Politica Respecto Derechos Humanos",
        en:"Policy Regarding Human Rights"
      },
      politicaRespectoDerechosHumanospdf:{
        es:"politicaRespetoDerechosHumanosES.pdf",
        en:"politicaRespetoDerechosHumanosEN.pdf"
      }
      ,
      DAAC:{
        es:"DAAC.pdf",
        en:"DAAC.pdf"
      }
      ,
      politicaSeguridad_: {
        es:"Politica Seguridad",
        en:"Security Policy"
      },
      APRONTAL: {
        es:"Aprobacion Instrumento Ambiental",
        en:"Approval of Environmental instrument"
      }
      ,
      politicaSeguridadpdf: {
        es:"politicaSeguridadES.pdf",
        en:"politicaSeguridadEN.pdf"
      }
      ,
      politicaSobornoCorrupcion:
      {
        es:"Politica Soborno Corrupcion",
        en:"Politics Bribery Corruption"
      }
      ,
      politicaSobornoCorrupcionpdf:
      {
        es:"politicaSobornoCorrupcionES.pdf",
        en:"politicaSobornoCorrupcionEN.pdf"
      }

 
    };
export {textoLenguajes};