import { Component, OnInit, Input } from '@angular/core';
import { textoLenguajes } from '../common/common';

@Component({
  selector: 'app-conocenos',
  templateUrl: './conocenos.component.html',
  styleUrls: ['./conocenos.component.scss']
})
export class ConocenosComponent implements OnInit {
  @Input() idioma: string;
  textos: Object;

  constructor() {
    this.textos = textoLenguajes;
  }

  ngOnInit() {
  }

  getTexto(tipo: string) {
    return this.textos[tipo][this.idioma];
  }

  ver(urlPdf: string) {
    let a: HTMLElement = document.querySelector('#idPdfCertifica');
    a.setAttribute('src', 'http://docs.google.com/gview?url=http://corporacionica.com/' + this.getTexto(urlPdf) + '&embedded=true');
    let t: HTMLElement = document.querySelector('#myModalCertifica');
    t.style.display = 'block';
  }

  cerrar() {
    let t: HTMLElement = document.querySelector('#myModalCertifica');
    t.style.display = 'none';
  }
}

export class conocenosComponent extends ConocenosComponent { }
