import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule} from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutisComponent } from './aboutis/aboutis.component';
import { ExportaComponent } from './exporta/exporta.component';
import { ProductosComponent } from './productos/productos.component';
import { CertificadosComponent } from './certificados/certificados.component';
import { GaleriaComponent } from './galeria/galeria.component';
import { ContactoComponent } from './contacto/contacto.component';
import { PoliticasComponent } from './politicas/politicas.component';
import { SostenibilidadComponent } from './sostenibilidad/sostenibilidad.component';
import { ResponzabilidadComponent } from './responzabilidad/responzabilidad.component';
import { Sostenibilidad1Component } from './sostenibilidad1/sostenibilidad1.component';
import { ConocenosComponent } from './conocenos/conocenos.component';
import { PrincipiosComponent } from './principios/principios.component';
import { DisponibilidadComponent } from './disponibilidad/disponibilidad.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutisComponent,
    ExportaComponent,
    ProductosComponent,
    CertificadosComponent,
    GaleriaComponent,
    ContactoComponent,
    PoliticasComponent,
    SostenibilidadComponent,
    ResponzabilidadComponent,
    Sostenibilidad1Component,
    ConocenosComponent,
    PrincipiosComponent,
    DisponibilidadComponent,
   

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
