import { Component, OnInit, Input } from '@angular/core';
import {textoLenguajes} from './../common/common';
@Component({
  selector: 'app-aboutis',
  templateUrl: './aboutis.component.html',
  styleUrls: ['./aboutis.component.scss']
})
export class AboutisComponent implements OnInit {
  @Input()
  idioma:string;
  textos: Object;
  constructor() { 
    this.textos = textoLenguajes;
  }

  ngOnInit() {
  }

  getTexto(tipo:string){
		return this.textos[tipo][this.idioma];
	}

}
