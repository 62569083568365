import { Component, OnInit, Input } from '@angular/core';
import {textoLenguajes} from './../common/common';

@Component({
  selector: 'app-certificados',
  templateUrl: './certificados.component.html',
  styleUrls: ['./certificados.component.scss']
})
export class CertificadosComponent implements OnInit {

  @Input()
  idioma:string;
  textos: Object;
  

  constructor() { 
    this.textos = textoLenguajes;

  }

  ngOnInit() {
  }

  getTexto(tipo:string){
		return this.textos[tipo][this.idioma];
	}

  ver(urlPdf:string){
    let a:HTMLElement = document.querySelector('#idPdfCertifica');
    a.setAttribute('src','https://docs.google.com/gview?url=http://corporacionica.com/'+this.getTexto(urlPdf)+'&embedded=true');
    let t:HTMLElement = document.querySelector('#myModalCertifica');
    t.style.display='block';
  }
  cerrar(){
    let t:HTMLElement = document.querySelector('#myModalCertifica');
    t.style.display='none';
  }
  

}
