import { Component, OnInit, Input,AfterViewInit, AfterContentInit } from '@angular/core';
import {textoLenguajes} from './../common/common';

@Component({
  selector: 'app-exporta',
  templateUrl: './exporta.component.html',
  styleUrls: ['./exporta.component.scss']
})
export class ExportaComponent implements OnInit, AfterContentInit {
  
  @Input()
  idioma:string;
  textos: Object;
  constructor() { 
    this.textos = textoLenguajes;
  }

  ngOnInit() {
    
    
  }
  ngAfterContentInit(): void {
    //setTimeout(eliminarStyle,2000);
  }

  getTexto(tipo:string){
		return this.textos[tipo][this.idioma];
  }
  
}
/*
function eliminarStyle():void{
  let items:any = document.querySelectorAll('#main-carousel .item');
  for (let index = 0; index < items.length; index++) {
    const element = items[index];
    element.removeAttribute('style');
  }
}
*/
