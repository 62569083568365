import { Component, OnInit, Input } from '@angular/core';
import { textoLenguajes } from '../common/common';

@Component({
  selector: 'app-sostenibilidad1',
  templateUrl: './sostenibilidad1.component.html',
  styleUrls: ['./sostenibilidad1.component.scss']
})

export class Sostenibilidad1Component implements OnInit {
  slideIndex:number = 1;
  @Input()
  idioma:string;
  textos: Object;
  constructor() { 
    this.textos = textoLenguajes;
  }

  getTexto(tipo:string){
		return this.textos[tipo][this.idioma];
	}
  // Open the Modal
  openModal():void {
    document.getElementById("myModal").style.display = "block";
  }

  // Close the Modal
  closeModal():void {
    document.getElementById("myModal").style.display = "none";
  }

  
  

  // Next/previous controls
  plusSlides(n):void {
    this.showSlides(this.slideIndex += n);
  }

  // Thumbnail image controls
  currentSlide(n):void {
    this.showSlides(this.slideIndex = n);
  }

  showSlides(n):void {
    let i;
    let slides= document.getElementsByClassName("mySlides");
    let dots = document.getElementsByClassName("demo");
    let captionText = document.getElementById("caption");
    if (n > slides.length) {this.slideIndex = 1}
    if (n < 1) {this.slideIndex = slides.length}
    for (i = 0; i < slides.length; i++) {
      let elemento:any = slides[i];
      elemento.style.display = "none";
    }
    for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" active", "");
    }
    let elementoE:any = slides[this.slideIndex-1]
    elementoE.style.display = "block";
    dots[this.slideIndex-1].className += " active";
    let elementoI:any = dots[this.slideIndex-1];
    captionText.innerHTML = elementoI.alt;
  }
  ngOnInit() {
    this.showSlides(this.slideIndex);
  }
}

export class sostenibilidad1Component extends Sostenibilidad1Component { }
