import { Component, OnInit,Input } from '@angular/core';
import {textoLenguajes} from './../common/common';

@Component({
  selector: 'app-sostenibilidad',
  templateUrl: './sostenibilidad.component.html',
  styleUrls: ['./sostenibilidad.component.scss']
})
export class SostenibilidadComponent implements OnInit {

  @Input()
  idioma:string;
  textos: Object;
  constructor() { 
    this.textos = textoLenguajes;
  }

  ngOnInit() {
  }

  getTexto(tipo:string){
		return this.textos[tipo][this.idioma];
	}

}
