import { Component, OnInit, Input  } from '@angular/core';
import {textoLenguajes} from './../common/common';

@Component({
  selector: 'app-responzabilidad',
  templateUrl: './responzabilidad.component.html',
  styleUrls: ['./responzabilidad.component.scss']
})
export class ResponzabilidadComponent implements OnInit {

  @Input()
  idioma:string;
  textos: Object;
  constructor() { 
    this.textos = textoLenguajes;
  }

  ngOnInit() {
  }

  getTexto(tipo:string): string{
    return this.textos[tipo][this.idioma];
		
  }
  ver(urlPdf:string){
    let a:HTMLElement = document.querySelector('#idPdf');
    a.setAttribute('src','http://docs.google.com/gview?url=http://corporacionica.com/'+this.getTexto(urlPdf)+'&embedded=true');
    let t:HTMLElement = document.querySelector('#myModal');
    t.style.display='block';
  }
  cerrar(){
    let t:HTMLElement = document.querySelector('#myModal');
    t.style.display='none';
  }
  
}



export class responzabilidadComponent extends ResponzabilidadComponent { }


