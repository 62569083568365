import { Component,AfterViewInit} from '@angular/core';
import * as $ from 'jquery';
import {textoLenguajes} from './../common/common';
import {IsotopeOptions} from 'isotope-layout';
import { get } from 'https';
//require ('isotope-layout');

@Component({
    selector: 'home-page',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})


export class HomeComponent  implements AfterViewInit {
	textos: Object;
	idioma: string;
	constructor(){
		this.textos = textoLenguajes;
		this.idioma='es';		
	}
	ngAfterViewInit(): void {
		//console.log('Datos');
		imageLoader(cImageSrc, startAnimation);		
	}
	closeModal():void{
		let cmodal:HTMLElement = document.querySelector('.closegal');
		if(cmodal != null){
			cmodal.click();
		}
	}
	getTexto(tipo:string){
		return this.textos[tipo][this.idioma];
	}
	setIdioma(idioma:string):void{
		this.idioma = idioma;
	}
}

  
$(function($) {
	// Navigation Scroll
	$(window).scroll(function(event) {
		Scroll();
	});	
	
	$('.navbar-collapse ul li a').click(function() {  
		let enlace = $(this).attr('href');
		$('html, body').animate({scrollTop: $(enlace).offset().top - 72}, 1000);
		return false;
	});
	
	// User define function
	function Scroll():void {
		let contentTop:  Array<number>    =   [];
		var contentBottom : Array<number>  =   [];
		var winTop : number    =   $(window).scrollTop();
		var rangeTop : number  =   200;
		var rangeBottom : number =   500;
		$('.navbar-collapse').find('.scroll a').each(function(){
			contentTop.push( $( $(this).attr('href') ).offset().top);
			contentBottom.push( $( $(this).attr('href') ).offset().top + $( $(this).attr('href') ).height() );
		})
		$.each( contentTop, function(i){
			if ( winTop > contentTop[i] - rangeTop ){
				$('.navbar-collapse li.scroll')
				.removeClass('active')
				.eq(i).addClass('active');			
			}
		})

	};
	
	// Slider Height
	var slideHeight = $('#main-carousel').height();
	//var slideHeight = $(window).height();
	$('#main-carousel .item').css('height',slideHeight);

	$(window).resize(function(){
		$('#main-carousel .item').css('height',slideHeight);
	});

	// Slider Height
	var slideSostenibilidadHeight = $('#main-carousel-sostenibilidad').height();
	//var slideHeight = $(window).height();
	$('#main-carousel-sostenibilidad .item').css('height',slideSostenibilidadHeight);

	$(window).resize(function(){
		$('#main-carousel-sostenibilidad .item').css('height',slideSostenibilidadHeight);
	});

	let $portfolio_selectors: any =null;
	let $portfolio :JQuery =null;

	// portfolio filter
	//$(window).load(function(){
	$(window).on("load",function(){
		$portfolio_selectors = $('.portfolio-filter >li>a');
		if($portfolio_selectors!='undefined'){
			$portfolio = $('.portfolio-items');
			jQuery($portfolio).isotope({
				itemSelector : '.portfolio-item',
				layoutMode : 'fitRows'
			});


			
			$portfolio_selectors.on('click', function(){
				$portfolio_selectors.removeClass('active');
				$(this).addClass('active');
				var selector = $(this).attr('data-filter');
				jQuery($portfolio).isotope({ filter: selector });
				return false;
			});
		}
		$('.preloader').fadeOut('fast',finalizarPreloader);
		
	});

	$('#folio-items').on('click','.mask a',function(event){
		event.preventDefault();

		var link = $(this).data('single_url');
		var full_url = '#portfolio-details',
			parts = full_url.split("#"),
			trgt = parts[1],
			target_top = $("#"+trgt).offset().top;

		$('html, body').animate({scrollTop:target_top}, 1200);
		$('#single-portfolio').slideUp(1000, function(){
			$(this).load(link,function(){
				$(this).slideDown(1000);
			});
		});
	});

	$('#portfolio-details').on('click','.close-item',function(){
		var full_url = '#folio-items',
			parts = full_url.split("#"),
			trgt = parts[1],
			target_offset = $("#"+trgt).offset(),
			target_top = target_offset.top;

		$('html, body').animate({scrollTop:target_top}, 1400);

		$("#single-portfolio").slideUp(1000);
	});	
	
});


	var cSpeed=7;
	var cWidth=75;
	var cHeight=75;
	var cTotalFrames=8;
	var cFrameWidth=75;
	var cImageSrc='assets/images/sprites.gif';
	
	let cImageTimeout:any=false;
	let genImage:any;
	function startAnimation():void{
		
		document.getElementById('loaderImage').innerHTML='<canvas id="canvas" width="'+cWidth+'" height="'+cHeight+'"><p>Your browser does not support the canvas element.</p></canvas>';
		

		let FPS: number = Math.round(100/cSpeed);
		let SECONDS_BETWEEN_FRAMES: number = 1 / FPS;
		let g_GameObjectManager = null;
		let g_run=genImage;

		g_run.width=cTotalFrames*cFrameWidth;

		//initCanvas();
		
	}
	function finalizarPreloader(){
		$('.preloader').css('display','none');
		$('#navigation').css('display','block');
		$('#videohome').css('display','block');
		$('#services').css('display','block');
		$('#politicas').css('display','block');
		$('#productos').css('display','block');
		$('#exportacion').css('display','block');
		$('#certificaciones').css('display','block');
		$('#sostenibilidad').css('display','block');
		$('#galeria').css('display','block');
		$('#footer').css('display','block');
		$('#responzabilidad').css('display','block');
		$('#conocenos').css('display','block');
		$('#principios').css('display','block');
		$('#disponibilidad').css('display','block');
    
    

	}
	
	function imageLoader(s:string, fun:Function)
	{
		clearTimeout(cImageTimeout);
		cImageTimeout=0;
		genImage = new Image();
		genImage.onload=function (){cImageTimeout=setTimeout(fun, 0)};
		genImage.onerror=new Function('alert(\'Could not load the image\')');
		genImage.src=s;
	}


