import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { Sostenibilidad1Component } from './sostenibilidad1/sostenibilidad1.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'sostenibilidad1', component: Sostenibilidad1Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }